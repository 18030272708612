.App {
  background-color:#fff;
}

.header {
  /* Header styles */
}

.slider {
  /* Slider styles */
}

.body {
  /* Body styles */
}

.footer {
  /* Footer styles */
}

@media screen and (max-width: 768px) {
  .main-display-container {
    
  }

  .header {
    /* Responsive header styles */
  }

  .slider {
    /* Responsive slider styles */
  }

  .body {
    /* Responsive body styles */
  }

  .footer {
    /* Responsive footer styles */
  }
}

@media screen and (max-width: 479px) {
  .main-display-container {

  }

  .header {
    /* Further responsive header styles */
  }

  .slider {
    /* Further responsive slider styles */
  }

  .body {
    /* Further responsive body styles */
  }

  .footer {
    /* Further responsive footer styles */
  }
}


.login-form-container, .login-form {
display: flex;
flex-direction: column;
text-align: center;
align-items: center;
justify-content: center;
color: white;
background-image: linear-gradient(79deg,#7439db, #C66FBC 48%, #F7944D);
}

.register-form-container, .register-form {
display: flex;
flex-direction: column;
text-align: center;
align-items: center;
justify-content: center;
color: white;
background-image: linear-gradient(79deg,#7439db, #C66FBC 48%, #F7944D);
}

@media screen and (min-width: 600px) {
  .login-form-container {
    padding: 4rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;
  }
  .register-form-container {
    padding: 4rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;
  }
}

label {
  text-align: left;
  padding: 0.3rem 0;
}

input {
  margin: 0.4rem 0;
  padding:0.5rem;
  border: none;
  border-radius: 10px;
}

button {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #7439db;
}

.link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}

/*************** Header styling **********************/

.header-container {
  margin-top: 4px;
}

.company-logo {
  width: 75px;
  height: 75px;
}

/***********************************************************************/

/* Dropdown container - needed to position the dropdown content */
.navbar-menu-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1px;
  background-color: #333;
  overflow: hidden;
}

.navbar-menu-list a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.navbar-menu-list .icon {
  display: none;
}

.nav-menu-col2, .nav-menu-col3, .nav-menu-col4, .nav-menu-col5, .nav-menu-col6, .nav-menu-col7, .nav-menu-col8, .nav-menu-col9, .nav-menu-col10, .nav-menu-col11 {
  float: left;
  overflow: hidden;
}

.nav-menu-col2 .dropbtn, .nav-menu-col3 .dropbtn, .nav-menu-col4 .dropbtn, .nav-menu-col5 .dropbtn, .nav-menu-col6 .dropbtn, .nav-menu-col7 .dropbtn, .nav-menu-col8 .dropbtn, .nav-menu-col9 .dropbtn, .nav-menu-col10 .dropbtn, .nav-menu-col11 .dropbtn {
  font-size: 17px;    
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.navbar-menu-list a:hover, .nav-menu-col2:hover .dropbtn, .nav-menu-col3:hover .dropbtn, .nav-menu-col4:hover .dropbtn, .nav-menu-col5:hover .dropbtn, .nav-menu-col6:hover .dropbtn, .nav-menu-col7:hover .dropbtn, .nav-menu-col8:hover .dropbtn, .nav-menu-col9:hover .dropbtn, .nav-menu-col10:hover .dropbtn, .nav-menu-col11:hover .dropbtn {
  background-color: #555;
  color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

.nav-menu-col2:hover .dropdown-content, .nav-menu-col3:hover .dropdown-content, .nav-menu-col4:hover .dropdown-content, .nav-menu-col5:hover .dropdown-content, .nav-menu-col6:hover .dropdown-content, .nav-menu-col7:hover .dropdown-content, .nav-menu-col8:hover .dropdown-content, .nav-menu-col9:hover .dropdown-content, .nav-menu-col10:hover .dropdown-content, .nav-menu-col11:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 600px) {
  .navbar-menu-list > div:not(:last-child) > a, .nav-menu-col2 .dropbtn, .nav-menu-col3 .dropbtn, .nav-menu-col4 .dropbtn, .nav-menu-col5 .dropbtn, .nav-menu-col6 .dropbtn, .nav-menu-col7 .dropbtn, .nav-menu-col8 .dropbtn, .nav-menu-col9 .dropbtn, .nav-menu-col10 .dropbtn, .nav-menu-col11 .dropbtn {
    display: none;
  }
  .navbar-menu-list a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .navbar-menu-list.responsive {position: relative;}
  .navbar-menu-list.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .navbar-menu-list.responsive, .navbar-menu-list.responsive > div > a {
    float: none;
    display: block;
    text-align: left;
  }
  .navbar-menu-list.responsive .nav-menu-col2, .navbar-menu-list.responsive .nav-menu-col3, .navbar-menu-list.responsive .nav-menu-col4, .navbar-menu-list.responsive .nav-menu-col5, .navbar-menu-list.responsive .nav-menu-col6, .navbar-menu-list.responsive .nav-menu-col7, .navbar-menu-list.responsive .nav-menu-col8, .navbar-menu-list.responsive .nav-menu-col9, .navbar-menu-list.responsive .nav-menu-col10, .navbar-menu-list.responsive .nav-menu-col11 {float: none;}
  .navbar-menu-list.responsive .dropdown-content {position: relative;}
  .navbar-menu-list.responsive .nav-menu-col2 .dropbtn, .navbar-menu-list.responsive .nav-menu-col3 .dropbtn, .navbar-menu-list.responsive .nav-menu-col4 .dropbtn, .navbar-menu-list.responsive .nav-menu-col5 .dropbtn, .navbar-menu-list.responsive .nav-menu-col6 .dropbtn, .navbar-menu-list.responsive .nav-menu-col7 .dropbtn, .navbar-menu-list.responsive .nav-menu-col8 .dropbtn, .navbar-menu-list.responsive .nav-menu-col9 .dropbtn, .navbar-menu-list.responsive .nav-menu-col10 .dropbtn, .navbar-menu-list.responsive .nav-menu-col11 .dropbtn  {
    display: block;
    width: 100%;
    text-align: left;
  }
}