.register-user-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
    background:#63738a;
    font-family: 'Roboto', sans-serif;
    width: auto;
    margin: 0 auto;
    padding: 30px 0;
    font-size: 20px;
}

.user-form-container {
 position: relative;
 background-color: #f2f3f7;
 color: black;
 width: 350px;
 height: 350px;
}

.register-user-container h2 {
    display: block;
    font-size: 1em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    font-weight: bold;
    margin-top: 25px;
    position: relative;
    text-align: center;
    color:  #636363;
    font-weight: 500;
    line-height: 1.2;
}

.uname {
    display: block;
    margin-left: 15px;
    margin-right: 15px;
}

.usubmit {
    margin-top: 25px;
}

.u-submit {
   width: auto;
   display: inline-block;
   padding: 10px 20px;
   background-image: linear-gradient(to right, red, #333);
   color: white;
   text-decoration: none;
}

@media screen and (max-width: 480px) {
    .user-form-container {
        position: relative;
        background-color: #f2f3f7;
        color: black;
        width: auto;
        height: 350px;
       }
}