.project-body-gallery {
    margin-left: 40px;
    margin-right: 40px;
}

.block-title {
    display: flex;
    width: auto;
    height: 100px;
    background-color: wheat;
    align-items: center;
    font-weight: 500;
    font-size: 21px;
    color: blue;
    margin-bottom: 10px;
    padding-left: 30px;
}

.gallery-item {
    display: flex;
    flex-direction: column;
}

.gallery-row {
    display: flex;
    justify-content: space-between;
}

.image-container {
    flex: 1;
    max-width: 350px;
    max-height: 350px;
    margin: 5px;
    overflow: hidden; /* Hide any overflow */
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;  /* Preserve the aspect ratio while covering the container */
}

.image-caption {
    color: black;
}

@media screen and (max-width: 950px) {
    .gallery-item {
        display: block;
        align-items: center;
        align-content: center;
    }

    .gallery-row {
        display: block;
    }

    .image-container {
        flex: 1;
        max-width: 700px;
        max-height: 500px;
        overflow: hidden; /* Hide any overflow */
    }
}

@media screen and (max-width: 780px) {
    .project-body-gallery {
        margin-left: 20px;
        margin-right: 20px;
    }
    .image-container {
        flex: 1;
        max-width: 680px;
        max-height: 500px;
        overflow: hidden; /* Hide any overflow */
    }
}

@media screen and (max-width: 480px) {
    .project-body-gallery {
        margin-left: 10px;
        margin-right: 10px;
    }
}