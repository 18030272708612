.main-body-tmc {
    margin-bottom: 100px;
    margin-left: 100px;
    margin-right: 100px;
    align-items: center;
    background-color: #fff;
}


@media screen and (max-width: 768px) {
    .main-body-tmc {
        margin-bottom: 100px;
        margin-left: 20px;
        margin-right: 20px;
        align-items: center;
        background-color: #fff;
    }
  }