.main-pe-cont {
    display: flex;
    height: 100vh;
    background-color: #673AB7;
    max-height: 100%;
    justify-content: center;
    flex-direction: 'column';
    align-items: center;
    text-align: center;
    padding: 10px;
}

.h2-pi {
    color: #fff;
}

#b1001{
    background-color: #fff;
    height: 50px;
    width: 120px;
    margin: 10px;
}

.form-main {
    display: flex;
    background-color: #673AB7;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.form-main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #673AB7;
    max-width: 100%;
    max-height: 100%;
    padding: 10px;
}


@media screen and (max-width: 768px) {
    .main-pe-cont {
        display: block;
        height: 100vh;
        background-color: #673AB7;
        max-height: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px;
    }
  }