.main-cont-ab {
  margin-bottom: 100px;
  margin-left: 100px;
  margin-right: 100px;
  align-items: center;
  background-color: #fff;
  text-align: justify;
}


@media screen and (max-width: 550px) {
  .main-cont-ab {
    margin-bottom: 100px;
    margin-left: 25px;
    margin-right: 25px;
    align-items: center;
    background-color: #fff;
  }
}