.project-body-gallery-co {
    margin-left: 40px;
    margin-right: 40px;
}

.block-title-co {
    display: flex;
    width: auto;
    height: 100px;
    background-color: wheat;
    align-items: center;
    font-weight: 500;
    font-size: 21px;
    color: blue;
    margin-bottom: 10px;
    padding-left: 30px;
}

.gallery-item-co {
    display: flex;
    flex-direction: column;
}

.gallery-row-co {
    display: flex;
    justify-content: space-between;
}

.media-container-co {
    flex: 1;
    max-width: 400px;
    max-height: 400px;
    margin: 5px;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.media-caption-co {
    color: black;
}

.media-container-co iframe {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 950px) {
    .gallery-item-co {
        display: block;
        align-items: center;
        align-content: center;
    }

    .gallery-row-co {
        display: block;
    }

    .media-container-co {
        flex: 1;
        max-width: 700px;
        max-height: 500px;
        margin: 5px;
        position: relative;
        overflow: hidden;
        width: 100%;
    }

}

@media screen and (max-width: 780px) {
    .project-body-gallery-co {
        margin-left: 20px;
        margin-right: 20px;
    }
   
    .media-container-co {
        flex: 1;
        max-width: 680px;
        max-height: 500px;
        margin: 5px;
        position: relative;
        overflow: hidden;
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .project-body-gallery-co {
        margin-left: 10px;
        margin-right: 10px;
    }
}