/* Faq.css */

.faq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .faq-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }
  
  .icon {
    font-size: 14px;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
  }
  
  .open {
    transform: rotate(180deg);
  }
  
  .faq-answer {
    padding: 10px 0;
  }  