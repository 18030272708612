/* aboutInfytech.css */

body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #fff;
    color: #333;
    margin: 0;
    padding: 0px;
  }
  
  h1 {
    color: #008080;
    margin-bottom: 20px;
  }
  
  h2 {
    color: #008080;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  h3 {
    color: #008080;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 10px;
  }
  
  .section {
    margin-bottom: 30px;
    border-radius: 5px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .footer {
    text-align: center;
    margin-top: 40px;
    padding: 10px;
    background-color: #008080;
    color: #fff;
  }
  
  /* CSS for proper alignment of word content */
  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .left-column,
  .right-column {
    flex: 1;
  }
  
  .main-cont-ab{
    margin-bottom: 100px;
    margin-left: 100px;
    margin-right: 100px;
    align-items: center;
    background-color: #fff;
    text-align: justify;
  }